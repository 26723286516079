<template lang="pug">
  #app
    #debugging-details(
      v-if="showDebuggingDetails"
    )
      span Project time: {{debuggingDetails.projectTime}}
      span Recorder time: {{debuggingDetails.recorderTime}}
      span Scene index: {{debuggingDetails.sceneIndex}}
      span Frame: {{debuggingDetails.frame}}
      span Speed: {{debuggingDetails.timelineSpeed}}
      span Time range: {{debuggingDetails.timeRange}}
      span Cluster: {{debuggingDetails.clusterPath}}
      span Force capture: {{debuggingDetails.forceCapture}}
      span Force reload counter: {{debuggingDetails.forceReloadCounter}}
      span Video state interval counter: {{debuggingDetails.videoStateIntervalCounter}}
      span Reloaded video range: {{debuggingDetails.reloadedVideoRangeText}}
      span Video elements:
        ul.list
          li(
            v-for="(item, key) in debuggingDetails.videoElementsOnTimeRange"
            :key="`video_elements_on_time_range_${key}`"
          ) {{ item }}
      span Reloaded videos:
        ul.list
          li(
            v-for="(item, key) in debuggingDetails.reloadedVideoElementsOnTimeRange"
            :key="`reloaded_video_elements_in_time_range_${key}`"
          ) {{ item }}
      span Video ready state:
        ul.list
          li(
            v-for="(item, key) in debuggingDetails.videoReadyState"
            :key="`video_ready_state_${key}`"
          ) {{ item }}
    #canvas-watermark(
      v-if="showWaterMark"
      :style="watermarkStyle"
    )
      span.top-watermark(
        :style="watermarkImageStyle"
      )
        img(src="@/assets/images/watermark/video-top.png")
      span.bottom-watermark(
        :style="watermarkImageStyle"
      )
        img(src="@/assets/images/watermark/video-bot.png")

    #dom-counter(
      v-if="showDOMCount"
    ) DOM count: {{ totalDOM }}
    #hidden-tags
      input(
        id="execution_time"
        v-model="executionTime"
      )
      input(
        id="project_id"
        v-model="projectId"
      )
      input(
        id="canvas_width"
        v-model="canvasWidth"
      )
      input(
        id="canvas_height"
        v-model="canvasHeight"
      )
      input(
        id="webm_file"
        v-model="webmFile"
      )
      input(
        id="is_capture_ready"
        value="false"
      )
      input(
        v-model="activeOverlay"
      )
      input(
        id="is_all_videos_loaded"
        v-model="allVideosLoaded"
      )
    .blinking-dot(v-show="showDot") .
    #main-content

      ProjectPreview(
        :class="projectPreviewClass"
        v-if="scenesReady"
        isCaptureFrame=true
        currentSeekTime=currentSeekTime
        :scaleDimension="scaleDimension"
        @all-animation-set="allAnimationSet = true"
        :isRenderingAtScale="isRenderingAtScale"
        :projectStartTimeDuration="projectStartTimeDuration"
        :timeRange="timeRange"
      )
</template>

<script>
import {
  mapGetters, mapMutations, mapState, mapActions,
} from 'vuex';

import { debounce } from 'debounce';
import ProjectPreview from '@/components/ProjectCanvas/ProjectPreview/ProjectPreview.vue';
import helperMixin from '@/components/mixins/helper-mixins';

import { isEmpty, removeDuplicates } from '@/assets/scripts/utilities';

import Api from '@/services/api/Api';

let resetCanvasPreviewTimeout; // eslint-disable-line
export default {
  name: 'app',
  components: {
    ProjectPreview,
  },
  mixins: [
    helperMixin,
  ],
  data() {
    return {
      showDot: false,
      executionTime: 0,
      projectId: '',
      canvasWidth: 0,
      canvasHeight: 0,
      currentSeekTime: 0,
      timelineBar: new TimelineLite(), // eslint-disable-line
      // captureReady: false,
      designId: '', // get project or template id
      sceneId: '',
      sceneIndex: '',
      scenesReady: false,
      userId: '',
      scaleDimension: 1,
      minSize: 150, // rescale canvas dimension if lower than 150px of width or height
      totalDOM: 0,
      showDOMCount: true,
      allVideosLoaded: false, // TODO: change this to maybe allAnimationReady?
      tempAllVideosLoaded: false,
      allAnimationSet: false,
      isVideoExport: false, // if video expot or image export, this state will be triggered in puppeter
      isPremiumUser: false,
      isCanvasDimensionUpdated: false,
      projectMetaId: '',
      webmFile: '',
      isRenderingAtScale: false,
      convertLinksToHd: true,
      isExport: false,
      projectDuration: 5,
      showDebuggingDetails: false,
      videoElementReloaded: [],
      projectStartTimeDuration: 0,
      frame: 1,
      timeRange: {},
      clusterPath: '',
      videoReadyState: [],
      videoReadyStateInterval: '',
      forceCapture: false,
      // videosOnTimeRange: [],
      forceReloadCounter: 0,
      videoStateIntervalCounter: 0,
      isTeamProject: false,
    };
  },
  computed: {
    ...mapState([
      'currentAllScenesTime',
      'forceReloadAllVideoElements',
    ]),
    ...mapState('canvasElements', [
      'timelineSpeed',
      'activeOverlay',
      'activeFGWhiteBg',
      'activeFGBlackBg',
      'activeBackgroundElements',
      'videoElements',
      'loadedVideoElements',
      'elementsList',
      'allScenesTimeline',
    ]),
    ...mapGetters([
      'isDownloadPreviewOpened',
      'isProjectPreview',
      'getCurrentAllScenesTime',
      'getIsAllScenesPlaying',
      'getIsTemplate',
    ]),
    ...mapGetters('canvasElements', [
      'getAllScenesTimeline',
      'getAllScenesDuration',
      'getMainTimeline',
      'getCanvasElements',
      'getCanvasSize',
    ]),
    getCurrentTime() {
      const locationHref = new URL(window.location.href);
      let time = locationHref.searchParams.get('time');

      if (time === 'auto') {
        time = parseFloat(this.getAllScenesDuration / 2).toFixed(2); // get middle duration
      }

      if (time === '0') {
        time = 0.03; // fix white first frame
      }

      console.log('getCurrentTime', time);
      return parseFloat(time || 0.03);
    },
    projectPreviewClass() {
      return [{
        'only-show-overlay-elements': this.activeOverlay,
        'only-show-fg-elements': this.activeFGWhiteBg || this.activeFGBlackBg,
        'only-show-background-elements': this.activeBackgroundElements,
        'force-black-bg': this.activeOverlay || this.activeFGBlackBg,
        'force-white-bg': this.activeFGWhiteBg,
      }];
    },
    watermarkStyle() {
      const styleObject = {};

      let sceneScale;
      let canvasWidth = this.getCanvasSize.width;
      let canvasHeight = this.getCanvasSize.height;

      if (sceneScale < 1) {
        sceneScale = 1;
      } else {
        canvasWidth *= this.scaleDimension;
        canvasHeight *= this.scaleDimension;
      }

      let offsetLeft = canvasWidth * sceneScale / 2;
      let offsetTop = canvasHeight * sceneScale / 2;

      // maximize full with without scaling the wrapper
      if (this.isCaptureFrame) {
        offsetLeft = 0;
        offsetTop = 0;
        styleObject.left = 0;
        styleObject.top = 0;
      }

      styleObject.marginLeft = `-${offsetLeft}px`;
      styleObject.marginTop = `-${offsetTop}px`;
      styleObject.width = `${canvasWidth}px`;
      styleObject.height = `${canvasHeight}px`;
      styleObject.transform = `scale(${sceneScale})`;

      return styleObject;
    },
    watermarkImageStyle() {
      const styleObject = {};

      // eslint-disable-next-line
      styleObject.width = `${parseInt(this.getCanvasSize.width / 3)}px`;
      return styleObject;
    },
    showWaterMark() {
      return false;
      // return !this.isPremiumUser && !this.getIsTemplate && this.isVideoExport && !this.isTeamProject;
    },
    debuggingDetails() {
      const locationHref = new URL(window.location.href);
      const time = locationHref.searchParams.get('time'); // eslint-disable-line
      const recorderTime = this.currentAllScenesTime.toFixed(2);
      const projectTime = parseFloat(parseFloat(this.projectStartTimeDuration) + parseFloat(recorderTime)).toFixed(2);
      const sceneIndex = this.sceneIndex || 0;
      // const frame = parseInt(recorderTime / 0.03); // 30fps
      // const { frame } = this; // will be set in rendering side
      const frame = Math.round(projectTime * 30);

      return {
        projectTime,
        recorderTime,
        sceneIndex,
        frame,
        timeRange: this.timeRange ? this.timeRange : 'N.A.',
        clusterPath: this.clusterPath ? this.clusterPath : 'N.A.',
        forceCapture: this.forceCapture,
        reloadedVideoRangeText: `${this.reloadedVideoElementsOnTimeRange.length} of ${this.videoElementsOnTimeRange.length}`,
        videoElementsOnTimeRange: this.videoElementsOnTimeRange,
        reloadedVideoElementsOnTimeRange: this.reloadedVideoElementsOnTimeRange,
        videoReadyState: this.videoReadyState,
        forceReloadCounter: this.forceReloadCounter,
        videoStateIntervalCounter: this.videoStateIntervalCounter,
        timelineSpeed: this.timelineSpeed,
      };
    },
    videoElementsOnTimeRange() {
      return this.videoElements;
      // if(this.videosOnTimeRange.length === 0) {
      // }
      // return this.videoElements.filter(i => this.videosOnTimeRange.indexOf(i) !== -1);
    },
    reloadedVideoElementsOnTimeRange() {
      return this.videoElementReloaded;
      // return this.videoElementReloaded.filter(i => this.videosOnTimeRange.indexOf(i) !== -1);
    },
    videosOnTimeRange() {
      const elementList = this.elementsList.byId;
      return Object.keys(elementList).map(i => elementList[i]).filter(element => (element.type === 'videos' && element.show) && (element.time_in >= this.timeRange.end || element.time_out >= this.timeRange.start)).map(item => item.data.urlHd);
    },
  },
  methods: {
    ...mapMutations([
      'setIsProjectPreview',
      'setIsDownloadPreviewOpened',
      'setIsTemplate',
      'isAllScenesPlaying',
      'setCurrentAllScenesTime',
      'pushCustomFonts',
      'setVp9Videos',
      'setForceReloadAllVideoElements',
    ]),
    ...mapMutations('canvasElements', [
      'clearScene',
      'updateCanvasSize', // mutation so that it doesn't save history
      'updateProjectDetails',
      'updateTimelineSpeed',
      'setDefaultWatermark',
      'setWatermark',
    ]),
    ...mapActions('canvasElements', [
      'updateScenesElements',
    ]),
    canvasGet() {
      const captureFrameResource = {
        id: this.designId,
        is_template: this.getIsTemplate,
        convert_links_to_hd: this.convertLinksToHd,
        scene_id: this.sceneId,
        scene_index: this.sceneIndex,
        project_meta_id: this.projectMetaId,
      };

      // convert_links_to_hd -> to change the asset links in api
      Api.captureFrameResource(captureFrameResource).then((response) => {
        if (response.data.success) {
          this.clearScene();

          console.log('response', JSON.parse(JSON.stringify(response)));
          const projectDetails = JSON.parse(JSON.stringify(response.data.results));
          projectDetails.isTemplate = this.getIsTemplate;

          this.userId = projectDetails.user_id;

          this.isPremiumUser = projectDetails.is_premium_user || false;

          if (typeof projectDetails.scenes !== 'undefined') {
            this.updateScenesElements({
              newScenes: projectDetails.scenes,
            });
          }
          this.updateCanvasSize({
            width: projectDetails.canvas_width,
            height: projectDetails.canvas_height,
            notSmartResize: true,
          });

          this.isCanvasDimensionUpdated = true;

          console.log('projectDetails', JSON.parse(JSON.stringify(projectDetails)));
          this.updateProjectDetails(projectDetails);

          if (typeof projectDetails.watermark !== 'undefined') {
            this.setWatermark(projectDetails.watermark);
          } else {
            this.setDefaultWatermark();
          }

          let sourceTab = 'capture-frame-';
          sourceTab += this.getIsTemplate ? `template-${this.designId}` : this.designId;

          document.title = sourceTab;

          this.projectDuration = this.isExport && this.sceneIndex && projectDetails.scenes.length > 1
            ? (projectDetails.scenes[0].duration + 1) // if recording per scene, capture only the first scene duration + 1s for the next duration
            : projectDetails.project_scene_duration;

          // computation for v1 export
          if (process.env.VUE_APP_EXPORT_VERSION === 'v1') {
            this.executionTime = this.projectDuration * (100 / (this.timelineSpeed * 100)); // multiply by timeline speed
          }

          if (projectDetails.canvas_height > projectDetails.canvas_width && projectDetails.canvas_height < this.minSize) {
            this.scaleDimension = this.minSize / projectDetails.canvas_height; // portrait
          } else if (projectDetails.canvas_height < projectDetails.canvas_width && projectDetails.canvas_width < this.minSize) {
            this.scaleDimension = this.minSize / projectDetails.canvas_width; // landscape
          } else if (projectDetails.canvas_height < this.minSize) {
            this.scaleDimension = this.minSize / projectDetails.canvas_height; // square
          }

          // eslint-disable-next-line
          this.canvasWidth = parseInt(projectDetails.canvas_width * this.scaleDimension);
          // eslint-disable-next-line
          this.canvasHeight = parseInt(projectDetails.canvas_height * this.scaleDimension);

          this.projectId = this.getIsTemplate ? `template-${this.designId}` : this.designId;
          this.scenesReady = true;
          this.initCustomFonts(this.userId);
          this.initCustomProjectFonts(this.designId, this.getIsTemplate);

          if (response.data.webm_file) {
            this.webmFile = response.data.webm_file;
          } else {
            this.webmFile = `${this.projectId}.webm`;
          }

          // Get team details if user is in team workspace
          if (projectDetails.team && projectDetails.team.id) {
            if (projectDetails.team.brand) {
              const teamBrandIds = projectDetails.team.brand.map((brand) => brand.brand_id); // eslint-disable-line
              this.initTeamCustomFonts(projectDetails.team.id, teamBrandIds);
            }

            this.isTeamProject = true;
          }

          this.projectStartTimeDuration = projectDetails.project_start_time_duration;

          setTimeout(() => {
            console.log(this.getCurrentTime);
            this.setCurrentAllScenesTime(this.getCurrentTime);
            this.saveVideoElementsToBlob();
            // this.captureReady = true; // must be triggered by puppeteer
          }, 1000);
        }
      }).catch((error) => {
        console.log(error);
        setTimeout(() => {
          this.canvasGet();
        }, 2000);
      });
    },
    resetCanvasPreview() {
      this.timelineBar.pause();
      this.timelineBar.seek(this.getCurrentTime);
      this.getAllScenesTimeline.pause();
      this.getAllScenesTimeline.seek(this.getCurrentTime);
      this.$root.$emit('canvas-element-animation-updated');
      this.setCurrentAllScenesTime(this.getCurrentTime);
      this.isAllScenesPlaying(false);
      this.$root.$emit('pauseAudio');
    },
    videoReadyStateInit() {
      console.log('videoReadyStateInit');
      let loop = 0;

      this.videoElementReloaded = [];
      this.videoReadyState = [];

      clearInterval(this.videoReadyStateInterval);
      this.videoReadyStateInterval = setInterval(() => {
        console.log('videoReadyStateInterval started', this.reloadedVideoElementsOnTimeRange.length, this.videoElementsOnTimeRange.length);
        // if (loop >= maxLoop) {
        // // try reloading all unresponsive videos;
        //   console.log('videoReadyStateInterval force reload', this.reloadedVideoElementsOnTimeRange.length, this.videoElementsOnTimeRange.length);
        //   this.setForceReloadAllVideoElements(true);
        //   loop = 0;
        // }

        this.videoReadyState = [];
        this.videoElementReloaded = [];
        // this.videosOnTimeRange = [];
        Array.from(document.getElementsByTagName('video')).forEach((item) => {
          const videoUrl = item.getAttribute('data-url');

          // const videosOnTimeRange = JSON.parse(JSON.stringify(this.videosOnTimeRange));
          // videosOnTimeRange.push(videoUrl);
          // this.videosOnTimeRange = removeDuplicates(videosOnTimeRange);

          // ready state
          const tmpVideoReadyState = JSON.parse(JSON.stringify(this.videoReadyState));
          const videosAndReadyStateText = `[${item.readyState}][${item.currentTime}] ${videoUrl}`;
          tmpVideoReadyState.push(videosAndReadyStateText);
          this.videoReadyState = removeDuplicates(tmpVideoReadyState);

          const videoElementReloaded = JSON.parse(JSON.stringify(this.videoElementReloaded));

          if (typeof videoElementReloaded[videoUrl] !== 'undefined') {
            delete videoElementReloaded[videoUrl];
          }

          if (item.readyState >= 3) {
            videoElementReloaded.push(videoUrl);
          }

          this.videoElementReloaded = removeDuplicates(videoElementReloaded);
        });

        // console.log('this.videoElementReloaded', JSON.parse(JSON.stringify(this.videoElements)), JSON.parse(JSON.stringify(this.videoElementReloaded)), JSON.parse(JSON.stringify(this.videoElementsOnTimeRange)));

        if (this.reloadedVideoElementsOnTimeRange.length >= this.videoElementsOnTimeRange.length) {
          this.videoStateIntervalCounter = 0;
          clearInterval(this.videoReadyStateInterval);
          console.log('videoReadyStateInterval cleared', this.reloadedVideoElementsOnTimeRange.length, this.videoElementsOnTimeRange.length);
        }

        this.videoStateIntervalCounter += 1;
        loop += 1;
      }, 1000);
    },
  },
  beforeMount() {
    const locationHref = new URL(window.location.href);
    const captureFrameToken = locationHref.searchParams.get('token');
    if (captureFrameToken !== process.env.VUE_APP_CAPTURE_FRAME_TOKEN) {
      document.body.innerHTML = 'Access forbidden';
      return;
    }

    const isTemplate = locationHref.searchParams.get('is_template') === 'true' || false;
    const timelineSpeed = parseFloat(locationHref.searchParams.get('timeline_speed') || process.env.VUE_APP_TIMELINE_SPEED);

    this.isExport = locationHref.searchParams.get('is_export') === 'true' || false;
    this.showDebuggingDetails = locationHref.searchParams.get('debug') === 'true' || false;
    this.convertLinksToHd = locationHref.searchParams.get('convert_links_to_hd') !== 'false';
    this.projectMetaId = locationHref.searchParams.get('project_meta_id') || '';
    this.isRenderingAtScale = !isEmpty(this.projectMetaId);

    this.showDOMCount = locationHref.searchParams.get('showDOMCount') === 'true' || false;
    let timeRange = locationHref.searchParams.get('time_range') || '';

    this.timeRange = {
      start: 0,
      end: this.projectDuration,
    };
    if (timeRange) {
      timeRange = timeRange.split('-');
      this.timeRange = {
        start: parseFloat(timeRange[0]), // eslint-disable-line
        end: parseFloat(timeRange[1]), // eslint-disable-line
      };
    }
    // console.log(process.env);
    // console.log(captureFrameToken);
    // console.log(process.env.VUE_APP_CAPTURE_FRAME_TOKEN);


    document.body.classList.add('no-scroll');

    if (window.location.href.indexOf('capture-frame') !== -1) {
      document.body.classList.add('is-capturing-frame');
    }

    this.setVp9Videos(locationHref.searchParams.get('vp9_videos') === 'true' || false);

    this.designId = locationHref.searchParams.get('id') || '';
    this.sceneId = locationHref.searchParams.get('scene_id') || '';
    this.sceneIndex = locationHref.searchParams.get('scene_index') || '';
    this.setIsTemplate(isTemplate);

    if (this.isExport) {
      this.updateTimelineSpeed(timelineSpeed);
      console.log('updateTimelineSpeed', timelineSpeed);
      setInterval(() => {
        this.showDot = !this.showDot;
      }, 100);
    }

    if (this.designId) {
      this.canvasGet();
    }
  },
  mounted() {
    // valid url
    // capture-frame?token={process.env.VUE_APP_CAPTURE_FRAME_TOKEN}&id={project or template id}&time={selected_time}&is_export={bool}&is_template{bool}
    // this.getAllScenesTimeline.kill().clear().addLabel('Start', 0);
    this.setIsDownloadPreviewOpened(true);
    this.setIsProjectPreview(true);
    console.log(this.timelineBar);
    this.$root.$on('canvas-container-reset-timeline', debounce(() => {
      console.log('====on CaptureFrame emit');
      // clearTimeout(resetCanvasPreviewTimeout);
      // resetCanvasPreviewTimeout = setTimeout(() => {
      //   this.resetCanvasPreview();
      // }, 200);
      this.totalDOM = this.$el.getElementsByTagName('*').length;
    }));

    // using the watch:currentAllScenesTime
    // this.$root.$on('video-element-reloaded', (videoUrl) => {
    //   const videoElementReloaded = JSON.parse(JSON.stringify(this.videoElementReloaded));
    //   videoElementReloaded.push(videoUrl);
    //   this.videoElementReloaded = removeDuplicates(videoElementReloaded);

    //   // console.table({
    //   //   videoElementsOnTimeRange: this.videoElementsOnTimeRange,
    //   //   reloadedVideoElementsOnTimeRange: this.reloadedVideoElementsOnTimeRange,
    //   // });
    // });
    this.totalDOM = this.$el.getElementsByTagName('*').length;

    // debugging force failed
    // setTimeout(() => {
    //   throw new Error('test some error');
    // }, 3000);
    this.videoReadyStateInit();
  },
  watch: {
    allAnimationSet(val) {
      console.log('ALL ANIMATION SET:', val);
    },
    currentAllScenesTime(val) {
      // console.log('CaptureFrame.vue:currentAllScenesTime', val);

      // this.videoElementReloaded = [];

      // // const maxLoop = 5;
      // this.$nextTick(() => {
      //   if (this.reloadedVideoElementsOnTimeRange.length >= this.videoElementsOnTimeRange.length) {
      //     clearInterval(this.videoReadyStateInterval);
      //   } else {
      //     this.videoReadyStateInit();
      //   }
      // });
    },
    videoElements(val) {
      // console.log('videoElements', val);
    },
    loadedVideoElements(val) {
      // console.log('loadedVideoElements', val);
      this.videoElementReloaded = removeDuplicates(val);
    },
    forceReloadAllVideoElements(val) {
      if (!val) {
        return;
      }
      this.forceReloadCounter += 1;
      // on VideoElement.vue when it forcedReloadAllvideos, it will also clearup the blob file of unresponsive video
      this.$nextTick(() => {
        this.saveVideoElementsToBlob();
      });

      this.videoReadyStateInit();

      setTimeout(() => {
        // automatically return to false
        this.setForceReloadAllVideoElements(false);
      }, 300);
    },
    elementsList(val) {
      console.log('elementsList', val);
    },
    videosOnTimeRange(val) {
      console.log('videosOnTimeRange', val);
    },
    // getCanvasElements() {
    //   // eslint-disable-next-line
    //   this.videosOnTimeRange = this.getCanvasElements.filter(element => (element.type === 'videos' && element.show) && (element.time_in >= this.timeRange.end || element.time_out >= this.timeRange.start)).map(item => item.data.urlHd);
    // },
    timelineSpeed(newVal) {
      if (process.env.VUE_APP_EXPORT_VERSION === 'v1') {
        return;
      }

      // this computation is only for v2
      const timelineSpeedMultiple = (100 / (newVal * 100));
      const timeRangeDuration = this.timeRange.end - this.timeRange.start;
      if (timeRangeDuration > 0) {
        this.executionTime = timeRangeDuration * timelineSpeedMultiple;
      } else {
        this.executionTime = this.projectDuration * timelineSpeedMultiple;
      }
    },
  },
};
</script>

<style scoped lang="scss">
  #debugging-details {
    position: fixed;
    top: 0;
    background: rgb(0 0 0 / 70%);
    font-size: 12px;
    color: #fff;
    display: block;
    z-index: 999;
    padding: 10px;
    width: 400px;
    display: flex;
    font-family: arial;
    justify-content: center;
    flex-direction: column;
    word-break: break-word;
     > span {
      margin-bottom: 8px;
    }
    .list {
      margin: 0;
      padding: 0;
      padding-left: 15px;
    }
  }
</style>

<style lang="scss">
@import '~normalize.css';
@import '~@/assets/styles/components/filter-tab.scss';

* {
  box-sizing: border-box;
}

body.is-capturing-frame {
  // no scroll class is added beforeMount
  // only for canvas and capture frame
  &.no-scroll {
    overflow: hidden;
  }

  // hide preloader in capture frame
  .element__loader {
    display: none;
  }

  #dom-counter {
    font-family: 'Courier New', Courier, monospace;
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    padding: 10px;
    display: inline-block;
    color: #000;
    z-index: 99;
  }

  #main-content{
    overflow: hidden;
    position: relative;
    background: #fff;

    .canvas-modal__content {
      border-radius: unset;
      background: transparent;
    }
  }

  .crisp-client {
    display: none !important;
  }

  .drr.canvas-element{
    .split-word,
    .split-text{
      vertical-align: top;
    }
  }

  .project-preview__canvas {
    border-radius: 0;
  }
}

#hidden-tags{
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  left: 0;
  top: 0;
  z-index: 999;
}

.blinking-dot {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 2px;
  color: #333;
  width: 1px;
  height: 1px;
  /*z-index: 19999;*/
}

// 'only-show-overlay-elements': this.activeOverlay,
// 'only-show-fg-elements': this.activeFGWhiteBg,
// 'force-black-bg': this.activeOverlay || this.activeFGBlackBg,
// 'force-white-bg': this.activeFGWhiteBg,

.force-black-bg{
  background: #000;
}
.force-white-bg{
  background: #fff;
}
.only-show-overlay-elements{
  .canvas-element{
    &:not(.is-layer-overlay){
      display: none !important;
    }
  }
}
.only-show-fg-elements{
  .canvas-element{
    &:not(.is-layer-fore-ground){
      display: none !important;
    }
  }

  &.force-black-bg{
    // update contrast and brightness of element
    .canvas-element{
      &.is-layer-fore-ground{
        .element-wrap{
          > *{
            filter: contrast(0) saturate(1) brightness(2) blur(0px) hue-rotate(0deg) sepia(0) !important;
          }
        }
      }
    }
  }
}
.only-show-background-elements{
  .canvas-element{
    &:not(.is-layer-background){
      display: none !important;
    }
  }
}
#canvas-watermark{
  position: relative;

  .top-watermark,
  .bottom-watermark{
    position:absolute;
    z-index: 300;
    img{
      width: 100%;
    }
  }

  .top-watermark{
    top: 0;
    left: 0;
  }
  .bottom-watermark{
    right: 0;
    bottom: -5px;
  }
}
</style>
